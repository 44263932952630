import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Users from "./views/Users";
import FormikForm from "./components/Form";

function App() {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("userId");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="users/:userId"
          element={
            <div className="form-container">
              <FormikForm setOpen={setOpen} open={open} />
            </div>
          }
        />
        <Route path="/users" element={<Users />} />
        <Route
          path="*"
          element={
            userId !== null ? (
              <Navigate to={`/users/${userId}`} replace />
            ) : (
              <Navigate to={`/users`} replace />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
