const PostResultData = (values, allValues, userId, data) => ({
  loanOfficerUID: userId,
  landingFormData: {
    basic: {
      youngestOwnerAge: allValues.values.youngestAge,
      isBirthDaySoon: false,
      propertyValue: Number(allValues.values.propertyValue.replace(/[^0-9]/g, ""))
    },
    creditAndProperty: {
      creditHistory: "Excellent",
      monthlyInstallmentPayment: 0,
      minMonthlyCreditCardPayments: 0,
      propertyType: "Single Family Residence",
      yearBuilt: 1977,
      numberPeopleInHome: 1,
      isPayingHOADues: false,
      monthlyAmount: 0,
      estimatedSquareFeet: 2000,
      isAnyOtherProperties: false,
      state: allValues.values.state
    },
    incomeAndAssets: {
      householdMonthlyIncome: 10000,
      checkingSavingsAccounts: 0,
      taxableInvestmentAccount: 0,
      additionalAssets: 0
    },
    mortgages: {
      firstMortgageBalance: Number(allValues.values.mortgageBalances.replace(/[^0-9]/g, "")),
      firstMortgageMonthlyPayment: 1,
      yearsLeftOnMortgage: 1,
      areTaxesEscrowed: false,
      annualTaxes: 1,
      annualInsurance: 1,
      homeEquityLine: 0,
      monthlyHelocPayment: 0,
      yearsOnHomeEquityLine: 0
    }
  },
  scenarioTitle: data.matchedScenario.title || "",
  scenarioData: data.scenarioData || {},
  leadCaptureData: {
    borrowers: [
      {
        firstName: values.name,
        email: values.email
      }
    ],
    mainObjective: ""
  },
  type: "APP"
});

export default PostResultData;
