const PostLandingData = (values, userId) => ({
  landingFormData: {
    basic: {
      youngestOwnerAge: values.youngestAge,
      isBirthDaySoon: false,
      propertyValue: Number(values.propertyValue.replace(/[^0-9]/g, ""))
    },
    creditAndProperty: {
      creditHistory: "Excellent",
      monthlyInstallmentPayment: 0,
      minMonthlyCreditCardPayments: 0,
      propertyType: "Single Family Residence",
      yearBuilt: 1977,
      numberPeopleInHome: 1,
      isPayingHOADues: false,
      monthlyAmount: 0,
      estimatedSquareFeet: 2000,
      isAnyOtherProperties: false,
      state: values.state
    },
    incomeAndAssets: {
      householdMonthlyIncome: 10000,
      checkingSavingsAccounts: 100000,
      taxableInvestmentAccount: 100000,
      additionalAssets: 100000
    },
    mortgages: {
      firstMortgageBalance: Number(values.mortgageBalances.replace(/[^0-9]/g, "")),
      firstMortgageMonthlyPayment: 1,
      yearsLeftOnMortgage: 1,
      areTaxesEscrowed: false,
      annualTaxes: 1,
      annualInsurance: 1,
      homeEquityLine: 0,
      monthlyHelocPayment: 0,
      yearsOnHomeEquityLine: 0
    }
  },
  parameters: {
    loanOfficerUID: userId
  }
});

export default PostLandingData;
