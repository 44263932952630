import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import useUserIdParam from "../hooks/useUserIdParam";
import { useSnackbar } from "notistack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Modal from "./Modal";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import FormatMoney from "../helpers";
import ValidationSchema from "./validation";
import createAxiosInstance from "../api/api";
import PostLandingData from "../api/PostLandingData";

const FormikForm = ({ open, setOpen }) => {
  const initialValues = {
    youngestAge: "",
    propertyValue: "",
    mortgageBalances: "",
    state: ""
  };

  const userId = useUserIdParam();

  const axiosInstance = createAxiosInstance();

  const [finalValue, setFinalValue] = useState("");
  const [allValues, setAllValues] = useState({});
  const [states, setStates] = useState([]);
  const [data, setData] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get(`/public/loan-officers/pwa/status`);
        console.log(data);
        if (data.valid) {
          if (data.loanOfficer.paidPWA) {
            setStates(data.loanOfficer);
            localStorage.setItem("userId", data.loanOfficerUID);
          } else {
            localStorage.removeItem("userId");
            enqueueSnackbar("You need to contact Loan Officer for access");
          }
        } else {
          enqueueSnackbar("No loan officer with such id");
        }
      } catch (e) {
        enqueueSnackbar(e.message);
      }
    }
    fetchData();
  }, []);
  // const [resultId, setResultId] = useState("");
  const openModal = () => {
    setOpen(true);
  };
  const SubmitForm = (values, { setSubmitting }) => {
    axiosInstance
      .post("landing-form", PostLandingData(values, userId))
      .then((response) => {
        console.log(response);
        setSubmitting(false);
        setAllValues({ values });
        setFinalValue(
          `${response.data.allScenarioData.scenarioData.finalValues.otherValues.netReverseMortgageFundsAfterPayingOffCurrentMortgageBalances}`
        );
        setData(response.data.allScenarioData);
      })
      .catch((e) => {
        setFinalValue("This is not qualified");
        setSubmitting(false);
        console.error(e.message);
      });
  };
  return (
    <div className="form">
      <h1>Reverse Mortgage Estimates</h1>
      <Box className="loanOfficer">
        Loan Officer:{" "}
        {states.firstName
          ? `${states.firstName} ${states.lastName}`
          : "No loan officer with this id"}
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={SubmitForm}>
        {({ values, isSubmitting, errors, touched }) => {
          return (
            <Form className="formInside">
              <Field
                className="input"
                name="youngestAge"
                as={TextField}
                label="Youngest Age"
                variant="outlined"
                margin="normal"
                type="number"
                fullWidth
              />
              {errors.youngestAge && touched.youngestAge ? (
                <div className="error-message">{errors.youngestAge}</div>
              ) : null}
              <Field
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                className="input"
                name="propertyValue"
                as={TextField}
                label="Property Value"
                value={FormatMoney(values.propertyValue)}
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
              />
              {errors.propertyValue && touched.propertyValue ? (
                <div className="error-message">{errors.propertyValue}</div>
              ) : null}
              <Field
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                className="input"
                name="mortgageBalances"
                as={TextField}
                value={FormatMoney(values.mortgageBalances)}
                label="Mortgage Balances"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
              />
              {errors.mortgageBalances && touched.mortgageBalances ? (
                <div className="error-message">{errors.mortgageBalances}</div>
              ) : null}
              <FormControl fullWidth margin="normal">
                <InputLabel id="select">State</InputLabel>
                <Field
                  className="input"
                  name="state"
                  as={Select}
                  labelId="select"
                  id="select"
                  label="State"
                  variant="outlined"
                  fullWidth>
                  {states?.states?.length
                    ? states?.states?.map((state) => (
                        <MenuItem key={state.abbreviation} value={state.state}>
                          {state.state}
                        </MenuItem>
                      ))
                    : null}
                </Field>
              </FormControl>
              {errors.state && touched.state ? (
                <div className="error-message">{errors.state}</div>
              ) : null}
              {states.paidPWA ? (
                <Box className="submitButton">
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting" : "Get results"}
                  </Button>
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", fontSize: "2em", fontWeight: 700 }}>
                  No access for this loan officer
                </Box>
              )}
            </Form>
          );
        }}
      </Formik>
      {finalValue !== "" ? (
        <Box className="finalValue">
          Net funds available, after paying off liens:{" "}
          <div className="finalCost">
            <span className="finalValueResult">
              {/\d/.test(finalValue)
                ? `$${FormatMoney(Math.round(finalValue / 1000) * 1000)}`
                : finalValue}
            </span>
          </div>
          <Button className="shareButton" onClick={openModal} variant="contained" color="primary">
            SHARE
          </Button>
          <Modal
            open={open}
            setOpen={setOpen}
            allValues={allValues}
            axiosInstance={axiosInstance}
            userId={userId}
            data={data}
          />
        </Box>
      ) : null}
    </div>
  );
};

export default FormikForm;
