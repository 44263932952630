import * as React from "react";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import PostResultData from "../api/PostResultData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const initialValues = {
  name: "",
  email: ""
};

const BasicModal = ({ open, setOpen, allValues, axiosInstance, userId, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };
  const SubmitForm = async (values, { setSubmitting }) => {
    await axiosInstance
      .post("public/calculation-results/", PostResultData(values, allValues, userId, data))
      .then((response) => {
        console.log(response);
        setSubmitting(false);
        setOpen(false);
        enqueueSnackbar("Data shared via email");
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(e.message);
      });
  };
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email value is required")
  });
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h1>Share data via email</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={SubmitForm}>
            {({ isSubmitting, errors, touched }) => {
              return (
                <Form className="formInside">
                  <Field
                    className="input"
                    name="name"
                    as={TextField}
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                  {errors.name && touched.name ? (
                    <div className="error-message">{errors.name}</div>
                  ) : null}
                  <Field
                    className="input"
                    name="email"
                    as={TextField}
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    fullWidth
                  />
                  {errors.email && touched.email ? (
                    <div className="error-message">{errors.email}</div>
                  ) : null}
                  <Box className="submitButton">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}>
                      {isSubmitting ? "Submitting" : "Send"}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
