import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  youngestAge: Yup.string().required("Age is required"),
  propertyValue: Yup.string().required("Property value is required"),
  mortgageBalances: Yup.string().required("Mortgage balances is required"),
  state: Yup.string().required("State is required")
});

export default ValidationSchema;
