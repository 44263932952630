import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import axios from "axios";

import config from "../config";

const Users = () => {
  const initialValue = {
    Uid: ""
  };

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    function redirectToUser() {
      const userId = localStorage.getItem("userId");
      return userId !== null ? navigate(`/users/${userId}`) : null;
    }
    redirectToUser();
  }, []);

  const SubmitForm = (values, { setSubmitting }) => {
    try {
      axios
        .get(`${config.apiUrl}/public/loan-officers/pwa/status`, {
          headers: {
            "loan-officer-uid": values.Uid
          }
        })
        .then((response) => {
          if (response.data.valid) {
            navigate(`/users/${response.data.loanOfficerUID}`);
          } else {
            enqueueSnackbar("No loan officer with such id");
          }
        });

      setSubmitting(false);
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };
  return (
    <Formik initialValues={initialValue} onSubmit={SubmitForm}>
      {({ isSubmitting }) => {
        return (
          <div className="form-container" style={{ height: "95vh", display: "flex" }}>
            <div className="form" style={{ justifyContent: "center" }}>
              <Form
                className="formInside"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                <Field
                  className="input"
                  name="Uid"
                  as={TextField}
                  label="Loan Officer UID"
                  variant="outlined"
                  margin="normal"
                  type="text"
                  fullWidth
                />
                <Box className="submitButton">
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting" : "Get results"}
                  </Button>
                </Box>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default Users;
