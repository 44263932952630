import numeral from "numeral";

const FormatMoney = (value) => {
  if (!value && value !== 0) {
    return "";
  }

  return numeral(value).format("0,0");
};

export default FormatMoney;
