import axios from "axios";
import useUserIdParam from "../hooks/useUserIdParam";
import config from "../config";

export default function createAxiosInstance() {
  const userId = useUserIdParam();

  return axios.create({
    baseURL: config.apiUrl,
    headers: {
      accept: "application/json",
      "loan-officer-uid": userId
    }
  });
}
